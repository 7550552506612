import React, { Component } from "react";

import { ReactComponent as IconUpload } from "./svg/IconUpload.svg";

export class Upload extends Component {
  onSelectFile = () => {
    this.file.click();
  };

  onSelectedFile = () => {
    const { api, location, heading, sid } = this.props;
    const file = this.file.files[0];
    fetch(
      api(`upload/${location}/${heading}/${encodeURIComponent(file.name)}`),
      {
        method: "POST",
        headers: {
          Authorization: `sid ${sid}`,
          "Content-Type": "application/octet-stream"
        },
        body: file
      }
    ).then(this.props.reload || Function);
  };

  render() {
    return (
      <div style={{ display: "inline-block" }}>
        <form target="upload-frame" style={{ display: "none" }}>
          <input
            ref={ref => (this.file = ref)}
            type="file"
            onChange={this.onSelectedFile}
          />
        </form>
        <iframe
          name="upload-frame"
          title="upload-frame"
          src=""
          style={{ display: "none" }}
        />
        <button
          className="button"
          onClick={this.onSelectFile}
          title="Upload file"
        >
          <IconUpload /> Upload file
        </button>
      </div>
    );
  }
}
