export const api = method =>
  `${window.location.origin
    .replace("localhost:3000", "localhost:3141/arplain")
    .replace(/.*?arplain.com/, "https://pan0.net/arplain")
    .replace(
      /.*?--arplain.netlify.com/,
      "https://pan0.net/arplain"
    )}/${method}`;

export const googleLogin = () => {
  const OAUTHURL = "https://accounts.google.com/o/oauth2/auth?";
  const SCOPE = "https://www.googleapis.com/auth/userinfo.email";
  const CLIENTID =
    "967088289083-qbv63of3i71478e8cpvhn04askkresvs.apps.googleusercontent.com";
  const REDIRECT = window.location.origin;
  const TYPE = "token";
  const url = [
    OAUTHURL,
    `scope=${SCOPE}`,
    `&client_id=${CLIENTID}`,
    `&redirect_uri=${REDIRECT}`,
    `&response_type=${TYPE}`
  ].join("");
  window.location.replace(url);
};

export const loadUser = () => {
  const result = JSON.parse(localStorage.getItem("user") || "{}");
  if (!result.name && result.email) {
    result.name = result.email.split("@").shift();
  }
  return result;
};

export const clearUser = () => localStorage.removeItem("user");

export const checkLoggedIn = () => {
  const sid = loadUser().sid;
  if (!sid) return Promise.resolve(false);
  // Expired login?
  return fetch(api("is_logged_in"), {
    headers: { Authorization: `sid ${sid}` }
  })
    .then(r => r.json())
    .then(({ is_logged_in }) => {
      if (!is_logged_in) {
        clearUser();
      }
      return is_logged_in;
    });
};

export const checkLogin = () => {
  try {
    const params = JSON.parse(
      `{"${decodeURI(window.location.hash.substring(1))
        .replace(/"/g, '\\"')
        .replace(/&/g, '","')
        .replace(/=/g, '":"')}"}`
    );
    if (params.access_token) {
      window.location.hash = "";
      return fetch(api("login/google/" + params.access_token), { mode: "cors" })
        .then(response => response.json())
        .then(user => {
          localStorage.setItem("user", JSON.stringify(user));
        })
        .catch(clearUser);
    }
    return Promise.resolve(null);
  } catch (SyntaxError) {
    return Promise.resolve(null);
  }
};

export const logout = () => {
  const sid = loadUser().sid;
  if (!sid) {
    clearUser();
    return;
  }
  fetch(api("logout"), { headers: { Authorization: `sid ${sid}` } }).then(
    () => {
      clearUser();
      window.location.reload();
    }
  );
};

export const getObjects = (latitude, longitude) => {
  return Promise.resolve([]);
  // const sid = loadUser().sid;
  // const transformObject = obj => ({
  //   ...obj,
  //   ...decode(obj.id)
  // });
  // return sid
  //   ? fetch(api(`objects/${latitude}/${longitude}/${sid}`)).then(objects =>
  //       objects.map(transformObject)
  //     )
  //   : Promise.resolve([]);
};
