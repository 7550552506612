import React, { Component } from "react";
import { Entity, Scene } from "aframe-react";
import geohash from "ngeohash";
import {
  api,
  logout,
  googleLogin as onGoogleLogin,
  loadUser,
  checkLoggedIn,
  checkLogin
  // getObjects
} from "./api";
import { Upload } from "./Upload";
import { ReactComponent as Arplain } from "./svg/Arplain.svg";
import { ReactComponent as IconMap } from "./svg/IconMap.svg";
import { ReactComponent as IconGoogle } from "./svg/IconGoogle.svg";
import { ReactComponent as IconSignout } from "./svg/IconSignout.svg";

import "./App.css";

const CELL_PRECISION = 8;
const CELL_COLOR = "#2b886a";
const CELL_TEXT_COLOR = "#000";
const SKY_COLOR = "#91b4c2";
const LIGHT_COLOR = "#fff";
const PLAYER_SPEED = 0.3;
const PLAYER_HEIGHT = 1.7;

// https://www.movable-type.co.uk/scripts/latlong.html
const toRadians = val => (val * Math.PI) / 180;
const toDegrees = val => (val * 180) / Math.PI;
const normalizeAngle = angle => {
  let result = 0;
  if (typeof angle === "number") {
    result = angle % 360;
    if (result < 0) {
      result += 360;
    }
  }
  return result;
};

const R = 6371e3; // Earth radius in meters

const getDistanceHeading = (lat1, lon1, lat2, lon2) => {
  const φ1 = toRadians(lat1);
  const λ1 = toRadians(lon1);
  const φ2 = toRadians(lat2);
  const λ2 = toRadians(lon2);
  const Δφ = toRadians(lat2 - lat1);
  const Δλ = toRadians(lon2 - lon1);
  const a =
    Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
    Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const distance = R * c;
  const y = Math.sin(λ2 - λ1) * Math.cos(φ2);
  const x =
    Math.cos(φ1) * Math.sin(φ2) -
    Math.sin(φ1) * Math.cos(φ2) * Math.cos(λ2 - λ1);
  const heading = toDegrees(Math.atan2(y, x));
  return { distance, heading };
};

const displace = (lat, lon, distance, heading) => {
  const θ = toRadians(heading);
  const φ1 = toRadians(lat);
  const λ1 = toRadians(lon);
  const φ2 = Math.asin(
    Math.sin(φ1) * Math.cos(distance / R) +
      Math.cos(φ1) * Math.sin(distance / R) * Math.cos(θ)
  );
  const λ2 =
    λ1 +
    Math.atan2(
      Math.sin(θ) * Math.sin(distance / R) * Math.cos(φ1),
      Math.cos(distance / R) - Math.sin(φ1) * Math.sin(φ2)
    );
  return { latitude: toDegrees(φ2), longitude: toDegrees(λ2) };
};

const fromTo = (hash1, hash2) => {
  const { latitude: lat1, longitude: lon1 } = geohash.decode(hash1);
  const { latitude: lat2, longitude: lon2 } = geohash.decode(hash2);
  if (isFinite(lat1) && isFinite(lat2)) {
    return getDistanceHeading(lat1, lon1, lat2, lon2);
  }
  return { distance: null, heading: null };
};

const toFrom = (hash, distance, heading) => {
  const { latitude, longitude } = geohash.decode(hash);
  const { latitude: lat2, longitude: lon2 } = displace(
    latitude,
    longitude,
    distance,
    heading
  );
  return geohash.encode(lat2, lon2, hash.length);
};

class App extends Component {
  constructor() {
    super();
    this.state = {
      location: window.localStorage.getItem("location") || "N/A",
      anchor: window.location.hash.match(/^#\w{12}$/)
        ? window.location.hash.replace(/^#/, "")
        : null,
      email: null,
      name: null,
      picture: null,
      sid: null,
      rotation: { x: 0, y: 0, z: 0 },
      objects: []
    };
    this.player = null;
    this.location8 = null;
    window.fromTo = fromTo;
    window.toFrom = toFrom;
    window.geohash = geohash;
    window.addEventListener("keydown", this.onKeyDown);
  }

  componentDidMount() {
    checkLoggedIn().then(is_logged_in => {
      is_logged_in && this.setState({ ...loadUser() });
    });
    checkLogin().then(() => this.setState({ ...loadUser() }));
    window.addEventListener("resize", this.forceUpdate);
    this.initGeolocation();
    this.initCompass();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.forceUpdate);
    clearInterval(this.compassPoll);
  }

  initGeolocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        this.onAcquireLocation,
        () => {
          this.setState({
            error: "Please, enable geolocation for arplain to work"
          });
        },
        { enableHighAccuracy: true }
      );
      this.positionWatcher = navigator.geolocation.watchPosition(
        this.onLocationChange
      );
    } else {
      // TODO: show error state
      this.setState({ error: "Browser does not support geolocation" });
    }
  }

  initCompass = () => {
    this.compassPoll = setInterval(() => {
      const rotation = this.player.getAttribute("rotation");
      this.setState({ rotation });
    }, 1000);
  };

  onGetObjects = () => {
    const location8 = this.state.location.substring(0, CELL_PRECISION);
    if (location8 === this.location8) return;

    this.location8 = location8;
    const { sid } = this.state;
    const hashes = [location8, ...geohash.neighbors(location8)];
    const requests = hashes.map(hash =>
      fetch(
        api(`objects/${hash}`, { headers: { Authorization: `sid ${sid}` } })
      ).then(r => r.json())
    );
    Promise.all(requests).then(objects => this.setState({ objects }));
  };

  onLocationChange = position => {
    const { latitude, longitude } = position.coords;
    const location = geohash.encode(latitude, longitude, 12);
    this.setState({ location });
    window.localStorage.setItem("location", location);
  };

  onAcquireLocation = position => {
    this.onLocationChange(position);
    this.onGetObjects();
  };

  onKeyDown = event => {
    const { onGetObjects } = this;
    let { location, rotation } = this.state;
    if (this.positionWatcher) {
      navigator.geolocation.clearWatch(this.positionWatcher);
      delete this.positionWatcher;
    }
    switch (event.key) {
      case "w":
      case "ArrowUp":
        location = toFrom(location, PLAYER_SPEED, -rotation.y);
        this.setState({ location }, onGetObjects);
        return;
      case "s":
      case "ArrowDown":
        location = toFrom(location, -PLAYER_SPEED, -rotation.y);
        this.setState({ location }, onGetObjects);
        return;
      case "a":
      case "ArrowLeft":
        location = toFrom(location, -PLAYER_SPEED, 90 - rotation.y);
        this.setState({ location }, onGetObjects);
        return;
      case "d":
      case "ArrowRight":
        location = toFrom(location, PLAYER_SPEED, 90 - rotation.y);
        this.setState({ location }, onGetObjects);
        return;
      default:
        return;
    }
  };

  render() {
    const {
      location,
      anchor,
      name,
      picture,
      sid,
      rotation,
      objects
    } = this.state;
    const location8 = location.substring(0, CELL_PRECISION);
    const { distance, heading: headingLocation } = fromTo(location, location8);
    const { distance: distanceAnchor, heading: headingAnchor } = anchor
      ? fromTo(location, anchor)
      : {};
    const θ = toRadians(headingLocation);
    const ρ = toRadians(headingAnchor);
    const originX = distance * Math.sin(θ);
    const originY = distance * Math.cos(θ);
    const hashes = [location8, ...geohash.neighbors(location8)];
    const coeffs = [
      [0, 0], //   center
      [0, 1], //   n
      [1, 1], //   ne
      [1, 0], //   e
      [1, -1], //  se
      [0, -1], //  s
      [-1, -1], // sw
      [-1, 0], //  w
      [-1, 1] //   nw
    ];
    const { distance: cellHeight } = fromTo(hashes[0], hashes[1]);
    const { distance: cellWidth } = fromTo(hashes[0], hashes[3]);
    return (
      <>
        <Scene
          fog={`type: exponential; color: ${SKY_COLOR}; density: 0.07; near: 10; far: 20`}
        >
          <Entity light={`type: ambient; color: ${LIGHT_COLOR}`} />
          <Entity primitive="a-sky" />
          <Entity
            primitive="a-text"
            value="North"
            width="24"
            position="0 2 -32"
            color={CELL_TEXT_COLOR}
          />
          <Entity
            primitive="a-text"
            value="East"
            width="24"
            position="32 2 0"
            rotation="0 270 0"
            color={CELL_TEXT_COLOR}
          />
          <Entity
            primitive="a-text"
            value="South"
            width="24"
            position="0 2 32"
            rotation="0 180 0"
            color={CELL_TEXT_COLOR}
          />
          <Entity
            primitive="a-text"
            value="West"
            width="24"
            position="-32 2 0"
            rotation="0 90 0"
            color={CELL_TEXT_COLOR}
          />

          {distanceAnchor && (
            <Entity
              primitive="a-text"
              value={`${Math.round(distanceAnchor)}m to #${anchor}`}
              width="24"
              position={`${Math.sin(Math.PI - ρ) * 40} 5 ${Math.cos(
                Math.PI - ρ
              ) * 40}`}
              rotation={`0 ${-headingAnchor} 0`}
              color={CELL_TEXT_COLOR}
            />
          )}

          {hashes.map((hash, i) => (
            <Entity
              primitive="a-plane"
              key={hash + i}
              position={`${originX + 0.2 + coeffs[i][0] * cellWidth} 0 ${-(
                originY +
                0.2 +
                coeffs[i][1] * cellHeight
              )}`}
              rotation="-90 0 0"
              width={cellWidth - 0.2}
              height={cellHeight - 0.2}
              color={CELL_COLOR}
            >
              <Entity
                primitive="a-text"
                value={hash}
                color={CELL_TEXT_COLOR}
                width="12"
                align="center"
              />
              {(objects[i] || []).map(obj => {
                const { distance, heading } = fromTo(hash, obj.position);
                const α = toRadians(heading);
                return (
                  <Entity
                    primitive="a-box"
                    key={obj.position}
                    rotation={`${90 - obj.heading} 90 90`}
                    position={`${distance * Math.sin(Math.PI - α)} ${-distance *
                      Math.cos(Math.PI - α)} 1.7`}
                    width="1.5"
                    height="1"
                    depth="0.02"
                    color="#333"
                  >
                    <Entity
                      primitive="a-image"
                      position="0 0 0.011"
                      width="1.48"
                      height="0.98"
                      src="from_moma.jpg"
                    />
                  </Entity>
                );
              })}
            </Entity>
          ))}

          <Entity
            _ref={ref => (this.player = ref)}
            camera=""
            position={`0 ${PLAYER_HEIGHT} 0`}
            rotation={`${rotation.x} ${rotation.y} ${rotation.z}`}
            look-controls=""
          />
        </Scene>

        <header>
          <Arplain />
          <span
            className="Compass"
            style={{
              display: "inline-block",
              transform: `rotate(${270 - rotation.y}deg)`
            }}
          >
            ➤
          </span>
          <a href={"#" + location} title="Anchor current location (⇦ to undo)">
            {location}
          </a>
        </header>
        {name ? (
          <nav>
            <div className="Profile button" tabIndex="0">
              <img src={picture} alt={name} /> {name}
              <div>
                <button className="button">
                  <IconMap /> My files
                </button>
                <Upload
                  {...{
                    api,
                    location,
                    heading: normalizeAngle(parseInt(rotation.y)),
                    sid
                  }}
                />
                <button className="button" onClick={logout}>
                  <IconSignout /> Sign out
                </button>
              </div>
            </div>
          </nav>
        ) : (
          <nav>
            <button className="button" onClick={onGoogleLogin}>
              <IconGoogle /> Sign in
            </button>
          </nav>
        )}
        <footer>
          <div>
            <a href="/about" title="About">
              About
            </a>
            <a href="/tos" title="Terms of Service">
              Terms of Service
            </a>
            <a href="/privacy" title="Privacy Policy">
              Privacy Policy
            </a>
          </div>
        </footer>
      </>
    );
  }
}

export default App;
